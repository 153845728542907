import {
  CastReceiverContext,
  PlaybackConfig,
  PlayerManager,
} from "chromecast-caf-receiver/cast.framework";
import {
  LoadRequestData,
  MessageType,
} from "chromecast-caf-receiver/cast.framework.messages";
import {
  EventType,
  MediaElementEvent,
  ErrorEvent,
} from "chromecast-caf-receiver/cast.framework.events";
import { CastDebugLogger } from "chromecast-caf-receiver/cast.debug";
import { CustomDataProp, CustomDrmIntegrationId } from "./types";
import { buildCustomDrmData } from "./utils/buildCustomDrmData";
import { customDataPropSchema, stringToJsonCheckSchema } from "./schema";
import { ContentProtectionConfigEnricher } from "./drm/ContentProtectionConfigEnricher";
import { WidevineConfigEnricher } from "./drm/WidevineConfigEnricher";

const LOG_RECEIVER_TAG = "Receiver";

export class Receiver {
  private readonly _context: CastReceiverContext;
  private readonly _playerManager: PlayerManager;
  private readonly _castDebugLogger: CastDebugLogger;

  constructor() {
    this._context = CastReceiverContext.getInstance();
    this._playerManager = this._context.getPlayerManager();

    this._castDebugLogger = CastDebugLogger.getInstance();

    this._castDebugLogger.setEnabled(process.env.WEB_ENVIRONMENT === "dev");
    this._castDebugLogger.loggerLevelByEvents = {
      "cast.framework.events.category.CORE": cast.framework.LoggerLevel.INFO,
      "cast.framework.events.EventType.MEDIA_STATUS":
        cast.framework.LoggerLevel.DEBUG,
    };
    if (!this._castDebugLogger.loggerLevelByTags) {
      this._castDebugLogger.loggerLevelByTags = {};
    }
    this._castDebugLogger.loggerLevelByTags[LOG_RECEIVER_TAG] =
      cast.framework.LoggerLevel.DEBUG;
    this._castDebugLogger.showDebugLogs(process.env.WEB_ENVIRONMENT === "dev");

    // Provide an interceptor for LOAD messages.
    this._playerManager.setMessageInterceptor(
      MessageType.LOAD,
      this._handleLoad
    );

    // Add basic event listeners
    this._playerManager.addEventListener(EventType.PLAY, this.handlePlayEvent_);
    this._playerManager.addEventListener(
      EventType.PAUSE,
      this.handlePauseEvent_
    );
    this._playerManager.addEventListener(
      EventType.ERROR,
      this.handleErrorEvent_
    );
  }

  // Start receiving requests from senders.
  public start() {
    this._context.start();
  }

  private parseCustomDrmData(
    content: { contentId: string; contentUrl?: string | undefined },
    parsedCustomData: CustomDataProp | undefined
  ) {
    const logPrefix = "[parseCustomDrmData]";

    if (!parsedCustomData) return undefined;

    console.log(`${logPrefix} Parsing customData from loadRequestData`);

    const contentProtection = buildCustomDrmData(content, parsedCustomData);

    if (contentProtection && "error" in contentProtection) {
      this.handleErrorEvent_({
        type: EventType.ERROR,
        error: new Error("Cannot match a DRM spec to provided source."),
      });

      return undefined;
    }

    return contentProtection;
  }

  // Setup playbackConfig with a sourceDescription license information passed by loadRequestData.
  private readonly _handleLoad = (loadRequestData: LoadRequestData) => {
    const logPrefix = "[_handleLoad]";
    console.log(
      `${logPrefix} Entering method with raw loadRequestData:`,
      loadRequestData
    );

    loadRequestData.media.contentType = stringToJsonCheckSchema.parse(
      loadRequestData.media.contentType
    );

    const parsedCustomData = customDataPropSchema
      .optional()
      .parse(loadRequestData.media.customData ?? loadRequestData.customData);

    const contentProtection = this.parseCustomDrmData(
      loadRequestData.media,
      parsedCustomData
    );

    console.log(`${logPrefix} Parsed contentProtection:`, contentProtection);

    console.log(
      `${logPrefix} Retrieving initial playbackConfig from _playerManager`
    );

    const playbackConfig = Object.assign(
      new PlaybackConfig(),
      this._playerManager.getPlaybackConfig()
    );

    console.log(`${logPrefix} Initial playbackConfig:`, playbackConfig);

    console.log(`${logPrefix} Setting shakaConfig within playbackConfig`);

    playbackConfig.shakaConfig = {
      preferredVideoCodecs: [
        "dvh1.05.04",
        "dvhe.05.04",
        "hvc1.2.4.L90.90",
        "hev1.2.4.L90.90",
        "hvc1.1.6.L120.90",
        "hev1.1.6.L120.90",
      ],
      preferredAudioCodecs: ["ec-3", "ac-3"],
    };

    // Check for contentProtection (DRM)
    console.log(
      `${logPrefix} Checking for DRM contentProtection in customData sources`
    );

    if (contentProtection) {
      createContentProtectionConfigEnricher(contentProtection)?.enrich(
        playbackConfig
      );
    } else {
      console.log(
        `${logPrefix} No contentProtection found or applicable for enrichment`
      );
    }

    console.log(
      `${logPrefix} Final playbackConfig after potential enrichment:`,
      playbackConfig
    );
    this._playerManager.setPlaybackConfig(playbackConfig);

    console.log(
      `${logPrefix} Returning successfully with parsedLoadRequestData:`,
      loadRequestData
    );

    return loadRequestData;
  };

  private readonly handlePlayEvent_ = (event: MediaElementEvent): void => {
    this._castDebugLogger.debug(
      LOG_RECEIVER_TAG,
      "PLAY event received",
      event.currentMediaTime
    );
  };

  private readonly handlePauseEvent_ = (event: MediaElementEvent): void => {
    this._castDebugLogger.debug(
      LOG_RECEIVER_TAG,
      "PAUSE event received",
      event.currentMediaTime
    );
    console.log(
      "getMediaInformation",
      this._playerManager.getMediaInformation()
    );
    console.log("getStats", this._playerManager.getStats());
    console.log("getPlaybackConfig", this._playerManager.getPlaybackConfig());
    console.log(
      "getTracks",
      cast.framework.CastReceiverContext.getInstance()
        .getPlayerManager()
        .getAudioTracksManager()
        .getTracks()
    );
  };

  private readonly handleErrorEvent_ = ({
    error,
    ...errorEvent
  }: ErrorEvent): void => {
    console.error(error);
    this._castDebugLogger.error(
      LOG_RECEIVER_TAG,
      "Detailed Error Code - " + errorEvent.detailedErrorCode
    );

    const errorProperties = {
      type: errorEvent.type,
      ...(errorEvent.detailedErrorCode
        ? { detailedErrorCode: errorEvent.detailedErrorCode }
        : {}),
      ...(errorEvent.reason ? { reason: errorEvent.reason } : {}),
    };

    newrelic.noticeError(error, errorProperties);
  };
}

// Create an enricher to apply the contentProtection properties to a playbackConfig instance.
export function createContentProtectionConfigEnricher(contentProtection: {
  licenseAcquisitionURL: string;
  integration?: CustomDrmIntegrationId | undefined;
  headers?: { [headerName: string]: string };
}): ContentProtectionConfigEnricher | undefined {
  // Widevine DRM
  switch (contentProtection.integration) {
    case "vudrm":

    // const preferredKeySystems = [
    //   "edef8ba9-79d6-4ace-a3c8-27dcd51d21ed",
    //   "9a04f079-9840-4286-ab92-e65be0885f95",
    //   "94ce86fb-07ff-4f43-adb8-93d2fa968ca2",
    // ];

    // return new VudrmWidevineConfigEnricher(
    //   {...contentProtection, preferredKeySystems},
    //   contentProtection as VudrmConfiguration & DrmConfiguration
    // );

    case "ezdrm":
    default:
      return new WidevineConfigEnricher(contentProtection);
  }
}
