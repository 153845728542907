import { CustomDataProp } from "src/types";

export const buildCustomDrmData = (
  content: { contentId: string; contentUrl?: string | undefined },
  parsedCustomData: CustomDataProp
) => {
  // Zod schema considers this data-shape scenario which is true of the Android native player
  if ("licenseAcquisitionURL" in parsedCustomData) return parsedCustomData;

  if ("sources" in parsedCustomData) {
    const selectedDrmSource = parsedCustomData.sources.find(
      (source) =>
        source.src === content.contentId || source.src === content.contentUrl
    );

    if (!selectedDrmSource) {
      return {
        error: new Error("Cannot match a DRM spec to provided source."),
      };
    }

    return {
      licenseAcquisitionURL:
        selectedDrmSource.contentProtection.widevine.licenseAcquisitionURL,
      integration: selectedDrmSource.contentProtection.integration,
      headers: selectedDrmSource.contentProtection.widevine.headers,
    };
  }
  return undefined;
};
