import { z } from "zod";

const licenseTypeSchema = z.enum(["temporary", "persistent"]);
const drmIntegrationIdSchema = z.enum(["vudrm", "ezdrm"]);

const keySystemConfigurationSchema = z.object({
  licenseAcquisitionURL: z.string().url(),
  licenseType: licenseTypeSchema.optional(),
  headers: z.record(z.string()),
  useCredentials: z.boolean().optional().default(false),
  queryParameters: z.record(z.any()).optional(),
});

const widevineKeySystemConfigurationSchema = keySystemConfigurationSchema;

const drmConfigurationSchema = z.object({
  integration: drmIntegrationIdSchema.optional(),
  widevine: widevineKeySystemConfigurationSchema,
  preferredKeySystems: z.array(z.string()),
});

const drmLicenseConfigSchema = z.object({
  licenseAcquisitionURL: z.string().url(),
  useCredentials: z.boolean(),
});

const sourceSchema = z.object({
  contentProtection: drmConfigurationSchema.optional(),
  drm: drmConfigurationSchema,
  src: z.string().url(),
});

export const customDrmIntegrationIdSchema = z.enum([
  "vudrm",
  "ezdrm",
  "custom",
  "keyos",
  "keyos_buydrm",
]);

const slimDrmSchema = z.object({
  licenseAcquisitionURL: z.string().url(),
  integration: customDrmIntegrationIdSchema.optional(),
  headers: z.record(z.string()),
});

export const customDrmConfigurationSchema = drmConfigurationSchema.extend({
  integration: customDrmIntegrationIdSchema.optional(),
  customIntegrationId: z.string().optional(),
  integrationParameters: z.record(z.unknown()).optional(),
  playready: drmLicenseConfigSchema.optional(),
  preferredKeySystems: z.array(z.string()).optional(),
});

const abrSchema = z.object({
  preferredAudioCodecs: z.array(z.string()).optional(),
});

const extendedSourceSchema = sourceSchema.extend({
  src: z.string().url(),
  type: z.string().optional(),
  contentProtection: drmConfigurationSchema,
  abr: abrSchema.optional(),
  crossOrigin: z.string().optional().nullable(),
  hlsDateRange: z.boolean().nullish(),
  experimentalRendering: z.boolean().optional(),
  ignoreEmbeddedTextTrackTypes: z.array(z.string()).optional(),
  lowLatency: z.boolean().optional(),
  nativeUiRendering: z.boolean().optional(),
  useCredentials: z.boolean().optional(),
  useNativePlayback: z.boolean().optional(),
});

const customSourceSchema = z
  .union([
    extendedSourceSchema.omit({ drm: true }).merge(
      z.object({
        contentProtection: customDrmConfigurationSchema,
      })
    ),
    extendedSourceSchema.omit({ contentProtection: true }).merge(
      z.object({
        drm: customDrmConfigurationSchema,
      })
    ),
  ])

  /**
   * Native apps send sources without drm property instead of contentProtection and without `widevine` prop
   * if that's the case populate contentProtection and `widevine`.
   */
  .transform((val) =>
    "drm" in val ? { ...val, contentProtection: val.drm } : val
  );

const adsSchema = z.array(z.unknown());
const textTracksSchema = z.array(z.unknown());
const emptyPosterSchema = z.string().transform(() => undefined);

const sourceDescriptionSchema = z.object({
  ads: adsSchema.optional(),
  poster: z.union([z.string().url().optional(), emptyPosterSchema]).optional(),
  sources: z.array(customSourceSchema),
  textTracks: textTracksSchema.optional(),
  analytics: z.unknown(),
  blockContentIfAdError: z.unknown(),
});

const stringToJsonSchema = z.string().transform((str, ctx) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    ctx.addIssue({
      code: "custom",
      message: "Invalid JSON format in string",
    });
    return z.NEVER;
  }
});

export const stringToJsonCheckSchema = z.string().transform((str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
});

const exoPlayerConfigSchema = z
  .object({
    exoPlayerConfig: z.object({
      headers: z.record(z.string()),
      licenseUrl: z.string().url(),
    }),
  })
  .passthrough()
  .transform((customData) => ({
    licenseAcquisitionURL: customData.exoPlayerConfig.licenseUrl,
    headers: customData.exoPlayerConfig.headers,
  }));

/**
 * This seems to be the case for iOS (no `sourceDescription` key) but not
 * Android (`sourceDescription` key present), so let's check for both.
 */
const platformSpecificCustomDataSchema = z.union([
  z
    .object({
      sourceDescription: z.union([
        stringToJsonSchema.pipe(sourceDescriptionSchema),
        sourceDescriptionSchema,
      ]),
    })
    .passthrough()
    .transform(({ sourceDescription, ...val }) => ({
      ...sourceDescription,
      ...val,
    })),
  sourceDescriptionSchema,
  exoPlayerConfigSchema,
]);

export const customDataPropSchema = z.union([
  stringToJsonSchema.pipe(platformSpecificCustomDataSchema),
  platformSpecificCustomDataSchema,
  slimDrmSchema,
]);
